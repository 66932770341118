/**
 * 配置
 * 表格列
 */
export const columns = [
	{
		title: "真实姓名",
		dataIndex: "actualName",
		width: "20%",
	},
	{
		title: "手机号",
		dataIndex: "tel",
		width: "20%",
	},
	{
		title: "角色",
		dataIndex: "roleNameList",
		width: "20%",
		customRender: function (roleNameList) {
			let arr = []
			for (let item of roleNameList) {
				arr.push(item.name)
			}
			return arr.toString()
		},
	},
	{
		title: "备注",
		dataIndex: "remake",
		width: "20%",
	},
	{
		title: "操作",
		dataIndex: "action",
		key: "action",
		width: "180",
		fixed: "right",
		scopedSlots: { customRender: "action" },
	},
]
// 搜索项
export const searchForm = {
	roleId: null,
	keyword: '',
}
// 分页
export const pagination = {
	current: 1,
	total: 0,
	pageSize: 10,
	showTotal: (total) => `共 ${total} 条`,
	showSizeChanger: true,
	showQuickJumper: true,
}