<template>
  <div>
    <a-modal :title="title" :visible="show" @ok="addSubmit" @cancel="addClose">
      <div>
        <a-form-model
          layout="vertical"
          ref="ruleForm"
          :model="form"
          :rules="rules"
        >
          <a-form-model-item label="角色名称" prop="name">
            <a-input v-model="form.name" placeholder="请输入角色名称" />
          </a-form-model-item>
          <a-form-model-item label="上级角色" prop="parentId">
            <a-select v-model="form.parentId" placeholder="请选择" >
				<a-select-option :key="0" :value="0">无上级</a-select-option>
				<a-select-option v-for="item in rolelist" :key="item.id" :value="item.id">{{item.name}}</a-select-option>
			</a-select>
          </a-form-model-item>
          <a-form-model-item label="权限等级" prop="authorityLevel">
            <a-input v-model.number="form.authorityLevel" placeholder="请选择" />
          </a-form-model-item>
          <a-form-model-item label="备注" prop="remakes">
            <a-textarea v-model="form.remakes" placeholder="请输入" />
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { roleInsert, roleUpdate } from "@/api/basic/role";
export default {
	props: {
		show: Boolean,
		title: String,
		id: Number,
		list:Array,
		upper: Number
	},
	data() {
		return {
			rules:{
				name: [{ required: true, message: '请输入', trigger: 'blur' }],
				parentId: [{ required: true, message: '请选择', trigger: 'change' }],
				authorityLevel: [{ required: true, message: '请输入', trigger: 'blur' }],
				remakes: [{ required: true, message: '请输入', trigger: 'blur' }],
			},
			form:{
				id: undefined,
				name: '',
				parentId: undefined,
				authorityLevel: '',
				remakes: '',
			},
			rolelist:[],
			editInfo:null
		};
	},
	methods: {
		addSubmit() {
			 if(this.form.id === undefined){
				 this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						const res = await roleInsert(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.addClose()
							this.success()
						}else{
							this.$message.error(res.msg)
						}
					}
				});
			 }else{
				 this.$refs.ruleForm.validate(async valid => {
					if (valid) {
						const res = await roleUpdate(this.form)
						if(res.code === 200){
							this.$message.success(res.msg)
							this.addClose()
							this.success()
						}else{
							this.$message.error(res.msg)
						}
					}
				});
			 };

		},
		findId(list,id){
			list.forEach(item => {
				if(item.id === id){
					this.editInfo = item
				}else if(item.childrenList !== null && item.childrenList ){
					this.findId(item.childrenList,id)
				}else{
					return
				}
			})
		},
		success(){
			this.$emit('success')
		},
		addClose() {
			this.$emit("close");
			this.$refs.ruleForm.resetFields();
			this.form.id = undefined;
		},
	},
	watch: {
		list:{
			handler(val){
				this.rolelist = []
				this.rolelist = val
			},
			deep:true
		},
		id:{
			handler(val){
				if(val){
					this.form.id = val
					this.findId(this.rolelist,val)
					// console.log(this.editInfo);
					this.form.name = this.editInfo.name
					this.form.parentId = this.editInfo.parentId
					this.form.authorityLevel = this.editInfo.authorityLevel
					this.form.remakes = this.editInfo.remakes
				}else if( val === undefined ){
					this.form.id = undefined
				}
			},
		},
		upper: {
			handler(val) {
				if(val) {
					this.form.parentId = val
				}
			}
		}
	}
};
</script>

<style>
</style>