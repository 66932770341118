import httpService from "@/request"

// 权限  角色
// 角色列表
export function getrolelist(params) {
	return httpService({
		url: `/user/role/list`,
		method: 'get',
		data: params,
	})
}
// 新增角色
export function roleInsert(params) {
	return httpService({
		url: `/user/role/insert`,
		method: 'post',
		data: params,
	})
}
// 编辑角色
export function roleUpdate(params) {
	return httpService({
		url: `/user/role/update`,
		method: 'post',
		data: params,
	})
}
// 分配角色
export function assignRoles(params) {
	return httpService({
		url: `/user/role/assignRoles`,
		method: 'post',
		data: params,
	})
}
// 删除角色
export function roleDel(params) {
	return httpService({
		url: `user/role/delete`,
		method: 'post',
		data: params,
	})
}
// 角色-----员工列表
export function listByRole(params) {
	return httpService({
		url: `/user/role/listByRole`,
		method: 'GET',
		params: params,
	})
}
// 查询当前角色下的所有的菜单权限信息 roleId
export function FindAllMenus(params) {
	return httpService({
		url: `/user/jurisdiction/findAllMenus`,
		method: 'GET',
		params: params,
	})
}
export function changeMenuShow(params) {
	return httpService({
		url: `/user/jurisdiction/isShowMenus`,
		method: 'post',
		data: params,
	})
}
export function getActions(params) {
	return httpService({
		url: `/user/jurisdiction/findOperationByMId`,
		method: 'get',
		params: params,
	})
}
export function changeActions(params) {
	return httpService({
		url: `/user/jurisdiction/isCheckOperation`,
		method: 'post',
		data: params,
	})
}
// 当前用户菜单
export function getMenu(params) {
	return httpService({
		url: `/user/manageUser/findAllMenusByUser`,
		method: 'GET',
		params: params,
	})
}
