<template>
  <div>
    <a-row>
      <!-- 角色列表 -->
      <a-col :span="5">
        <div class="cardTitle">所有角色</div>
        <a-collapse accordion @change="rolechange">
          <a-collapse-panel
            v-for="(item, index) in roleList"
            :key="index"
            :header="item.name"
          >
            <div v-for="(rolechild, indexs) in item.childrenList" class="role-card" :key="indexs" @click="rolechoose(rolechild.id)">
              <!-- <span class="role-li">
                <span>{{ rolechild.name || "无" }}</span>
                <span v-show="rolechild.name"> </span>
              </span> -->
			  <a-card style="background: rgba(32, 95, 189, 0.1)" v-if="roleId == rolechild.id" size="small">
				<span slot="title" style="color: #205FBD">{{rolechild.name}}</span>
				<span class="card-remark">{{rolechild.remakes}}</span>
			  </a-card>
			  <a-card v-else size="small" :title="rolechild.name">
				<span class="card-remark">{{rolechild.remakes}}</span>
			  </a-card>
            </div>
          </a-collapse-panel>
        </a-collapse>
      </a-col>
      <!-- 功能表格 -->
      <a-col :span="19">
        <div class="treebox">
          <a-tree
            v-model="checkedKeys"
			v-if="treeShow != undefined"
            checkable
			:expandedKeys="expandedKeys"
            :replace-fields="replaceFields"
            :selected-keys="selectedKeys"
            :tree-data="menus"
			@expand="onExpand"
            @select="onSelect"
            @check="onCheck"
          />
        </div>
      </a-col>
    </a-row>
    <a-drawer
      title="配置操作"
      placement="right"
      :closable="false"
      :visible="actions.show"
      @close="onClose"
    >
      <div class="content">
        <div v-if="actionsList.length === 0">无可配置操作</div>
        <a-checkbox
          v-for="action in actionsList"
          :checked="action.isCheck"
          :key="action.id"
          @change="onChange($event, action.id)"
        >
          {{ action.name }}</a-checkbox
        >
      </div>
    </a-drawer>
  </div>
</template>

<script>
import {
	getrolelist,
	FindAllMenus,
	changeMenuShow,
	getActions,
	changeActions,
} from "@/api/basic/role";
export default {
	data() {
		return {
			roleId: null,
			defaultExpandAll: true,
			roleList: [],
			expandedKeys: [],
			replaceFields: {
				children: "childrenList",
				title: "name",
				key: "id",
			},
			checkedKeys: {
				checked: [],
				halfChecked: [],
			},
			selectedKeys: [],
			activeKeys: [],
			menus: [], //页面菜单
			actionsList: [], //操作
			actions: {
				show: false,
			},
			treeShow: undefined,
		};
	},
	mounted() {
		this.getData();
	},
	methods: {
		getData() {
			getrolelist().then((res) => {
				this.roleList = res.data;
			});
		},
		rolechoose(data) {
			this.checkedKeys = {
				checked: [],
				halfChecked: [],
			};
			if (data !== undefined) {
				this.roleId = data;
				FindAllMenus({ roleId: this.roleId }).then((res) => {
					this.menus = res.data;
					this.defaultCheck(this.menus);
				});
			} else if (data === undefined) {
				this.roleId = null;
			}
		},
		rolechange(data) {
			this.treeShow = data;
			if (data != undefined) {
				this.checkedKeys = {
					checked: [],
					halfChecked: [],
				};
				this.roleId = this.roleList[data].id;
				FindAllMenus({ roleId: this.roleId }).then((res) => {
					this.menus = res.data;
					this.defaultCheck(this.menus);
				});
			} else {
				this.roleId = null;
				// this.menus = [];
			}
		},
		// defaultCheck(arr) {
		// 	for (let item of arr) {
		// 		if (item.childrenList != null) {
		// 			if(item.isShow == true){
		// 				this.checkedKeys.push(item.id);
		// 			}
		// 			this.defaultCheck(item.childrenList);
		// 		} else if (item.isShow == true) {
		// 			this.checkedKeys.push(item.id);
		// 		}
		// 	}
		// },
		defaultCheck(arr) {
			let res = {
				checked: [],
				halfChecked: [],
			};
			for(let x = 0; x<arr.length; x++) {
				let item = arr[x];
				for(let y = 0; y<arr[x].childrenList.length; y++) {
					let item = arr[x].childrenList[y];
					let checkedNum = 0
					if(item.childrenList) {
						for(let z = 0; z<arr[x].childrenList[y].childrenList.length; z++) {
							let item = arr[x].childrenList[y].childrenList[z];
							let checkedNum = 0
							if(item.isShow == true) {
								res.checked.push(item.id)
								checkedNum = checkedNum + 1
							}
							if( checkedNum == arr[x].childrenList[y].childrenList.length) {
								res.checked.push(arr[x].childrenList[y].id)
							} else if ( checkedNum != 0) {
								res.halfChecked.push(arr[x].childrenList[y].id)
							} else {
								
							}
						}
					}
					if(!item.childrenList) {
						if(item.isShow == true) {
							res.checked.push(item.id);
							checkedNum = checkedNum + 1
						}
						if(checkedNum == arr[x].childrenList.length) {
							res.checked.push(arr[x].id)
						} else if (checkedNum != 0) {
							res.halfChecked.push(arr[x].id)
						}
					}
				}
				if(!item.childrenList) {
					if(item.isShow == true) {
						res.checked.push(item.id)
					}
				}
			}
			this.expandedKeys = res.halfChecked.concat(res.checked)
			this.checkedKeys = res
		},
		onCheck(checkedKeys, e) {
			this.checkedKeys = checkedKeys;
			changeMenuShow({
				roleId: this.roleId,
				showMenusId: e.node.eventKey,
			}).then((res) => {
				if (res.code === 200) {
					this.$message.success(res.msg);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		onExpand(e) {
			this.expandedKeys = e;
		},
		onSelect(selectedKeys, info) {
			if(selectedKeys.length != 0) {
				this.selectedKeys = selectedKeys;
			}
			getActions({ roleId: this.roleId, menusId: this.selectedKeys[0] }).then(
					(res) => {
						this.actionsList = res.data;
						this.actions.show = true;
					}
				);
		},
		onChange(e, id) {
			changeActions({
				roleId: this.roleId,
				menusId: this.selectedKeys[0],
				operationId: id,
			}).then((res) => {
				if (res.code === 200) {
					this.$message.success(res.msg);
					this.onSelect(this.selectedKeys)
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		onClose() {
			this.actions.show = false;
		},
	},
	watch: {
		checkedKeys(val) {
		},
		roleList: {
			handler(val) {
				val.forEach((ele, index) => {
					if (ele.childrenList === undefined || ele.childrenList === null) {
						this.roleList[index].childrenList = [{}];
					}
				});
			},
			deep: true,
		},
	},
};
</script>

<style lang="less" scoped>
// .role-card {
//   line-height: 30px;
//   font-size: 16px;
//   cursor: pointer;
//   border-bottom: 1px solid #d9d9d9;
//   margin-bottom: 10px;
//   display: flex;
//   justify-content: space-between;
// }
.treebox {
  padding-left: 30px;
}
.tree {
  display: flex;
}
// .role-card {
//   line-height: 30px;
//   font-size: 16px;
//   cursor: pointer;
//   border-bottom: 1px solid #d9d9d9;
//   margin-bottom: 10px;
//   display: flex;
//   justify-content: space-between;
// }
.card-remark {
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	color: rgba(0, 0, 0, 0.45);
}
.role-li {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
/deep/.ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin: 0px;
}
</style>
