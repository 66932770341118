<template>
  <div>
    <a-tabs default-active-key="1"  @change="change">
      <a-tab-pane key="1" tab="人员角色分配">
        <a-row>
          <!-- 角色列表 -->
          <a-col :span="5">
            <div class="cardTitle">所有角色</div>	
            <!-- <a-button
              class="add-btn"
              style="margin: 10px"
              @click="addRole"
              >新增角色</a-button
            > -->
            <a-collapse accordion @change="rolechange">
              <a-collapse-panel
                v-for="(item, index) in afterroleList"
                :key="index"
                :header="item.name"
              >
			  	<span slot="extra">
					<a-button
					type="link"
					icon="plus"
					@click.stop="addRoleInner(item)"
					>
					</a-button>
					<a-button
					type="link"
					style="color: #ff4d4f"
					icon="delete"
					@click.stop="delRole(item.id)"
					></a-button>
				</span>
                <div v-for="(rolechild, indexs) in item.childrenList" class="role-card" :key="indexs" @click="rolechoose(rolechild.id)">
				  <a-card style="background: rgba(32, 95, 189, 0.1)" v-if="searchForm.roleId == rolechild.id" size="small">
					  <span slot="title" style="color: #205FBD">{{rolechild.name}}</span>
					  <span slot="extra" v-show="rolechild.name">
						  <a-button type="link" icon="form" @click="editRole(rolechild.id)"></a-button>
                          <a-button type="link" style="color: #ff4d4f" icon="delete" @click="delRole(rolechild.id)"></a-button>
					  </span>
					  <span class="card-remark">{{rolechild.remakes}}</span>
				  </a-card>
				  <a-card v-else size="small" :title="rolechild.name">
					  <span slot="extra" v-show="rolechild.name">
						  <a-button type="link" icon="form" @click="editRole(rolechild.id)"></a-button>
                          <a-button type="link" style="color: #ff4d4f" icon="delete" @click="delRole(rolechild.id)"></a-button>
					  </span>
					  <span class="card-remark">{{rolechild.remakes}}</span>
				  </a-card>
                </div>
              </a-collapse-panel>
			  <div style="height:46px">
				  <div @click="addRole" style="padding: 12px 0px 0px 16px;color: #205FBD">
					  <a-icon type="plus" />
					  <a style="margin-left: 10px">新建角色组</a>
				  </div>
			  </div>
            </a-collapse>
          </a-col>
          <!-- 人员表格 -->
          <a-col :span="19">
            <div class="search-box">
              <a-space size="large">
                <a-input
                  v-model="searchForm.keyword"
                  placeholder="请输入申请人/公司名"
                />
                <a-button type="primary" @click="getPeople">查 询</a-button>
                <a-button @click="reset">重 置</a-button>
              </a-space>
            </div>
			<div style="padding: 9px 16px 16px 20px">
            <!-- <commonTable
              :columns="columns"
              :tableData="tableData"
              :ActionsList="ActionsList"
			  :pagination="pagination"
              @handleTableChange="handleTableChange"
              @Actions="Actions"
              @selectionChoosed="selectionChoosed"
            >
              <template v-slot:actionBox="data">
                <a-space size="small">
                  <a class="ant-dropdown-link" @click="sendRole(data.data)"
                    >分配角色</a
                  >
                </a-space>
              </template>
            </commonTable> -->
			<a-table :columns="columns" :data-source="tableData" :pagination="pagination" @change="handleTableChange"
				:row-selection="{
					selectedRowKeys: selectedRowKeys,
					onChange: onSelectChange,
				}"
				:row-key="
					(record, index) => {
						return record.id;
					}
				"
			>
			  <template slot="action" slot-scope="text,record">
                  <a @click="sendRole(record)">分配角色</a>
              </template>
			</a-table>
			</div>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="2" tab="角色权限设置" force-render>
        <role-permissions ref="permissions"></role-permissions>
      </a-tab-pane>
    </a-tabs>
    <!-- <a-modal title="分配角色" :visible="send.show" @ok="sendSubmit" @cancel="sendClose">
		请选择角色：<el-cascader :options="roleList" :show-all-levels="false" :props="{ multiple: true, checkStrictly: true,value:'id',label:'name', children:'childrenList',disabled:'disabled'}" clearable  ></el-cascader></a-modal> -->
    <!-- 分配角色 -->
    <a-drawer
      title="分配角色"
      :width="720"
      :visible="send.show"
      :body-style="{ paddingBottom: '80px' }"
      @close="sendClose"
    >
      <div class="drawer-content">
        <div class="cardTitle">所有角色</div>
        <a-tree
          v-model="tree.checkedKeys"
          checkable
		  defaultExpandAll
          :selected-keys="tree.selectedKeys"
          :tree-data="roleList"
          :checkStrictly="true"
          :replaceFields="{
            children: 'childrenList',
            title: 'name',
            key: 'id',
          }"
          @select="onSelect"
          @check="onCheck"
        />
      </div>
      <div class="drawer-footer">
        <a-button :style="{ marginRight: '8px' }" @click="sendClose">
          关闭
        </a-button>
        <a-button type="primary" @click="sendSubmit"> 提交 </a-button>
      </div>
    </a-drawer>
    <!-- 新增角色 -->
    <add-form
      :show="add.show"
      :title="add.title"
      :list="roleList"
      @close="addClose"
      @success="success"
	  :upper="add.upperId"
      :id="add.editId"
    />
  </div>
</template>

<script>
import {columns, pagination, searchForm } from "./depend/config.js";
import {
	getrolelist,
	listByRole,
	assignRoles,
	roleDel,
} from "@/api/basic/role";
import rolePermissions from "./depend/permissions.vue";
import addForm from "./depend/form.vue";
export default {
	components: {
		rolePermissions,
		addForm,
	},
	data() {
		return {
			tree: {
				expandedKeys: [],
				autoExpandParent: true,
				checkedKeys: [],
				selectedKeys: [],
			},
			pagination,
			columns,
			searchForm,
			add: {
				show: false,
				title: "新增角色",
				upperId: null,
				editId: null,
			},
			tableChoosed: [],
			roleList: [],
			afterroleList: [],
			tableData: [], //   数据
			ActionsList: [],
			selectedRowKeys:[],
			send: {
				show: false,
			},
			form: {
				manageUserId: undefined,
				roleId: "",
			},
		};
	},
	watch: {
		afterroleList: {
			handler(val) {
				val.forEach((ele, index) => {
					if (ele.childrenList === undefined || ele.childrenList === null) {
						this.afterroleList[index].childrenList = [{ disabled: true }];
					}
				});
			},
			deep: true,
		},
	},
	mounted() {
		this.getData();
		this.getPeople();
	},
	methods: {
		getData() {
			getrolelist().then((res) => {
				this.roleList = res.data;
				this.afterroleList = JSON.parse(JSON.stringify(this.roleList));
			});
		},
		change(){
			this.$refs.permissions.getData()
		},
		getPeople() {
			listByRole({
				pageNum: this.pagination.current,
				size: this.pagination.pageSize,
				roleId: this.searchForm.roleId,
				keyword: this.searchForm.keyword,
			}).then((res) => {
				this.tableData = res.data.rows;	
				this.pagination.total = res.data.total
			});
		},
		success() {
			this.getData();
			this.getPeople();
		},
		addClose() {
			this.add.show = false;
			this.add.upperId = undefined;
			this.add.editId = undefined;
			this.add.title = "新增角色";
		},
		// 编辑角色
		editRole(id) {
			this.add.editId = id;
			this.add.title = "修改角色";
			this.add.show = true;
		},
		addRole() {
			this.add.editId = undefined;
			this.add.upperId = undefined;
			this.add.title = "新增角色";
			this.add.show = true;
		},
		addRoleInner(val) {
			this.add.editId = undefined;
			this.add.upperId = val.id;
			this.add.title = "新增角色";
			this.add.show = true;
		},
		// 删除角色
		delRole(id) {
			this.$confirm({
				title: "是否删除",
				// okText:'删除',
				// cancelText:'取消',
				icon: "delete",
				onOk: () => {
					roleDel({ roleId: id }).then((res) => {
						if (res.code === 200) {
							this.$message.success(res.msg);
							this.getData();
						} else {
							this.$message.error(res.msg);
						}
					});
				},
			});
		},
		rolechange(data) {
			if (data) {
				this.searchForm.roleId = this.roleList[data].id;
			} else {
				this.searchForm.roleId = null;
			}
			this.getPeople();
		},
		rolechoose(data) {
			if (data) {
				this.searchForm.roleId = data;
			} else {
				this.searchForm.roleId = null;
			}
			this.getPeople();
		},
		reset() {
			this.searchForm.keyword = "";
			this.getPeople();
		},
		handleTableChange(pagination) {
			this.pagination.current = pagination.current;
			this.pagination.pageSize = pagination.pageSize;
			this.getPeople()
		},
		sendRole(data) {
			this.form.manageUserId = data.id;
			let haveid = [];
			for (let name of data.roleNameList) {
				haveid.push(name.id);
			}
			this.tree.checkedKeys = haveid;
			this.send.show = true;
		},
		onExpand(expandedKeys) {
			// console.log('onExpand', expandedKeys);
			// if not set autoExpandParent to false, if children expanded, parent can not collapse.
			// or, you can remove all expanded children keys.
			this.tree.expandedKeys = expandedKeys;
			this.tree.autoExpandParent = false;
		},
		onCheck(checkedKeys) {
			const arr = [];
			this.tree.checkedKeys = checkedKeys;
			for (let k of this.tree.checkedKeys.checked) {
				arr.push(k);
			}
			this.form.roleId = arr.toString();
		},
		onSelect(selectedKeys, info) {
			this.tree.selectedKeys = selectedKeys;
		},
		onSelectChange(val) {
			this.selectedRowKeys = val
		},
		// multiRole(data){
		// 	console.log(data);
		// 	let arr = []
		// 	for(let a of data){
		// 		if(a.length > 0){
		// 			for(let b of a){
		// 				arr.push(b);
		// 			}
		// 		}else{
		// 			arr.push(a);
		// 		}
		// 	}
		// 	this.form.roleId = arr.toString()
		// },
		async sendSubmit() {
			if(this.form.roleId == ''){
				this.form.roleId = null
			};
			const res = await assignRoles(this.form);
			if (res.code === 200) {
				this.$message.success(res.msg);
				this.getPeople();
				this.sendClose();
			} else {
				this.$message.error(res.msg);
			}
		},
		sendClose() {
			this.form.manageUserId = undefined;
			this.send.show = false;
		},
		Actions(data) {
			// console.log(data);
		},
		selectionChoosed(data) {
			// console.log(data);
			this.tableChoosed = data;
		},
	},
};
</script>

<style lang="less" scoped>
// .role-card {
//   line-height: 30px;
//   font-size: 16px;
//   cursor: pointer;
//   border-bottom: 1px solid #d9d9d9;
//   margin-bottom: 10px;
//   display: flex;
//   justify-content: space-between;
// }
.card-remark {
	font-weight: 400;
	font-size: 12px;
	line-height: 17px;
	color: rgba(0, 0, 0, 0.45);
}
.role-li {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
</style>